@import "../../Styles/Variable.scss";
.team-item-wrapper{
    .member-details{
        p{
            font-size: 16px;
            font-weight: 500;
            color: #626A77;
        }
        h4{
            font-weight: 600;
        }
    }
    .team-image{
        img{
            width: 100%;
            border-radius: 10px;
        }
    }
    .main-holder{
        position: relative;
        cursor: pointer;
        .absolute-content{
            background: rgba(0, 32, 63, 0.77);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            visibility: hidden;
            transition: 0.3s ease-in-out all;
            opacity: 0;
            color: white;
            display: flex;
    align-content: flex-end;
    height: 100%;
        }
        &:hover{
            .absolute-content{
                visibility: visible;
                opacity: 1;
            }
        }
    }
}