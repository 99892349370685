@import '../../Styles/Variable.scss';
.footer-wrapper{
    background: #303030;
    .footer-header{
        width: 100%;
        .footer-design{
            // width: 100%;
            p{
                height: 5px;
                background: $primaryColor;
                min-width: 40px;
                max-width: 40%;
                margin-left: 0px;
            }
        }
        
        h5{
            margin-bottom: 0px;
            font-weight: 600;
        }
    }
    .footer-bottom{
        a{
            color: #dddddd;
            font-size: 18px;
            padding: 10px 0px;
            cursor: pointer;
            transition: 0.3s ease-out;
            &:hover{
                color: #B0D3EB;
            }
        }
    }
}
.footer-copyright{
    background: black;
    color: $primaryColor;
    font-size: 18px;
}
.social-navigation{
    .footer-social{
        background: black;
    border-top: 10px solid $primaryColor;
        transform: translateY(-10px);
        .social-icons{
            border-top: 1px solid #cccccc;
        }
    }
}
