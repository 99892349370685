@import './Variable.scss';

@media only screen and (max-width: 992px) {
    .custom-navigation {
        position: fixed;
        height: 100vh;
        width: 100%;
        background: white !important;
        color: $primaryColor !important;
        top: 0;

        .navogation-inner {
            flex-direction: column !important;

            .nav-item {
                a {
                    color: $primaryColor !important;
                }
            }

            .nav {
                display: flex;
                flex-direction: column;
            }
        }

        .overlay {
            top: 0px !important;
            padding-top: 0px !important;
        }
    }

    .main {
        padding-top: 65px !important;
        max-width: 100vw;
        overflow: hidden;
    }

    .mobile-navigation {
        position: fixed;
        z-index: 10;
        background: white;
        width: 100%;
        height: 65px;

        top: 0;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.09);
        overflow: hidden;
        transition: 0.5s ease-in-out all;
        
        .menu-shortcut {
            transform: rotate(180deg);

        }
        .top-bar-stripe{
            padding: 0px 15px;
            display: flex;
            justify-content: space-between;
            padding-right: 30px;
            height: 65px;
        }
        &.active{
            height: 100vh;
        }
        .bottom-details{
            overflow-y: scroll;
            height: calc(100% - 65px);
            padding-bottom: 20px;
            .button-style{
                border: 1px solid $primaryColor;
            }
            .sub-navigation-mobile{
                height: 0px;
                overflow: hidden;
                &.active{
                    height: auto;
                }
                a{
                    color: $linkColor;
                }
            }
        }
    }


    //banner items
    .home-main-slider {
        // height: 60vh !important;
        min-height: unset !important;
        background: $primaryColor !important;

        .banner-item {
            height: 60vh !important;
            position: relative;
            background: $primaryColor !important;

            img{
                width: 200%;
                // min-height: 60vh !important;    
                position: absolute;
            }
            .slider-text{
                font-size: 10vw !important;
            }
        }
    }

    //event sectionTitle
    .vertical-bar{
        position: relative !important;
        background: transparent !important;
        h1{
            writing-mode: unset !important;
            -webkit-text-orientation: upright;
            text-transform: none !important;
            text-orientation: upright;
            color: black !important;
            font-size: 38px;
            font-weight: 800 !important;
        }
    }
    .border-left-show{
        &:before{
            border: none !important;
        }
    }

    //course sectionTitle
    .course-card-wrapper{
        .course-image{
            height: 300px !important;
        }
        img{
            height:300px !important;
            width: auto;
            height: auto !important;
        }
    }

    //about section
    .about-section-wrapper {
        .floating--content{
            position: relative !important;
            transform: unset !important;
        }
    }
    .title-description{
        width: 100% !important;
        padding: 10px
    }
}