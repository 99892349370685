@import "../../../Styles/Variable.scss";

.event--section {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        height: 100px;
        width: 100%;
        bottom: 0px;
        left: 0px;
        background: $secondaryColor;
        z-index: -2;
    }

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        left: 0px;
        background: #F0F0F0;
        z-index: -2;
    }

    padding-bottom: 100px;
    position: relative;

    .border-left-show {
        &:before {
            content: "";
            height: 50%;
            position: absolute;
            bottom: 0;
            left: 0;
            border-left: 10px solid $secondaryColor;
            padding-left: 5px;
        }
    }

    .vertical-bar {
        background: $primaryColor;
        position: absolute;
        width: 100%;
        height: 160%;
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
            writing-mode: vertical-lr;
            text-orientation: upright;
            color: white;
            font-size: 38px;
            font-weight: 900;

        }
    }
}

.event-main-slider {
    .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 70% !important;
        }
    }
}

.event-list-items {
    .list-group {
        .list-group-item {
            cursor: pointer;
            border-radius: 0px;
            border: none;
            border-bottom: 1px solid #ccc;

            h5 {
                font-weight: 600;
                font-size: 20px;
            }

            p {
                // color: #ccc;
                margin-bottom: 0px;
                color: gray;
                font-size: 14px;
            }
        }
    }
}