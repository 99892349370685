@import "../../../Styles/Variable.scss";
.student-voices{
    background: darken($color: $primaryColor, $amount: 7);
    border-radius: 20px;
    min-height: 200px;
    P{
        font-size: 16px;
        line-height: 36px;
        font-weight: 300;
    }
    .item:focus{outline: none;}
    .caption{
        .image-holder{
            // width: 80px;
            height: 225px;
            // border-radius: 50%;
            overflow: hidden;
            img{
                min-width: 100%;
                height: 100%;
            }
        }
    }
}
