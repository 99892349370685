.features-item-wrapper{
    position: relative;
    min-height: 100px;
    margin: 15px 0px;
    .features-content{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 2;
        color: white;
        h4{
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
    }
    .background{
        .overlay{
            background: linear-gradient(0deg, rgba(2, 2, 2, 0.53), rgba(0, 0, 0, 0.53));
            position: absolute;
            width:100%;
            height: 100%;
            z-index: 1;
            top: 0;
            left: 0;
            transition: 1s ease-in-out all;
            &.colored{
                background: #00000086 !important;
            }
        }
        img{
            width:100%;
        }
    }
}