@import "../../Styles/Variable.scss";
.home-main-slider{
    .banner-item{
        position: relative;
        // height: calc(100vh - 20px);
        width: 100%;
        overflow: hidden;
        background: black;
        img{
            height: auto;
            width: 100%;
        }
        .overlay{
            // background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
            position: absolute;
            width:100%;
            height: 100%;
            z-index: 1;
            top: 0;
            left: 0;
            .slider-text{
                font-weight: bold;
                font-size: 3.7vw;
                line-height: 150.3%;
                margin-bottom: 20px;
            }
            .styled-rectangle{
                height: 30%;
                width: 300px;
                background: $primaryColor;
                position: absolute;
                top: 37%;
                transform: translateY(-50%);

            }
        }
        // .content{
        //     position: absolute;
        // }
    }
}
.banner-indicator{
    position: absolute;
    bottom: 60px;
    // transform: translateX(-50%);
    // left: 50%;
    width: 100%;
}