.news-small{
    .image-holder{
        height: 200px;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        img{
            position: absolute;

        }
    }
}
.news-big{
    position: relative;
    .image{
        overflow: hidden;
        height: 450px;
    }
}
.news__wrapper{
    .news-small{
        border-bottom: 1px solid #DFDFDF;
    }
    &:last-child{
        .news-small{
            border: none;
        }
    }
}