@import "../../Styles/Variable.scss";
.admission-page{
    .title-section{
        background: $primaryColor;
        h3{
            color: white;
            font-weight: bold;
        }
    }
}
.step-form-wrapper{
    .step-form-header{
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
    }
}

.form-section{
    .form-wrapper{
        background: white;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
    }
}

.success-response{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}