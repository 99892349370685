@import "../../Styles/Variable.scss";
.section-title-wrapper{
    .title-content{
        // padding: 40px;
        margin: 40px 0px 10px 0px;
        h2{
            font-weight: 800;
        }
        position: relative;
    }
    &.title-light{
        .title-content{
            color: white;
        }
    }
}