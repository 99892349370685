@import "../../Styles/Variable.scss";
.enrollment-wrapper{
    .index-indicator{
        position:absolute;
        width: 30px;
        height: 30px;
        background: $primaryColor;
        display: flex;
        align-items: center;
        justify-content: center;
        
        transform: rotate(45deg);
        top: -15px;
        z-index: 1;
        span{
            font-size: 18px;
        font-weight: bold;
        transform: rotate(-45deg);


        }
    }
    h4{
        text-transform: uppercase;
        font-size: 24px;
        font-weight: 800;
    }
    p{
        font-size: 18px;
        font-weight: 600;
    }
    padding: 20px;
    border-radius: 10px;
    align-items: center;
    .icon{
        margin-right: 20px;
    }
    &.enrollment-primary{
        color: white;
        background: $primaryColor;
        .index-indicator{
            background: $primaryColor;
            color: white;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.50);
        }
    }
    &.enrollment-secondary{
        color: white;
        background: $secondaryColor;
        .index-indicator{
            color: white;
            background: $secondaryColor;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.50);
        }
    }
    &.enrollment-default{
        color: black;
        background: white;
        .index-indicator{
            background: white;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.50);
        }
    }
}