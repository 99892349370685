@import "../Styles/Variable.scss";
$theme-colors: (
    // "info": tomato,
    // "danger": teal,
    "primary": $primaryColor,
);
$headings-font-family: 'Poppins', sans-serif;
$font-family-sans-serif: 'Poppins', sans-serif;
@import '~bootstrap/scss/bootstrap';
@import '../Styles/CustomLayout.scss';
@import '../Styles/Animation.scss';
@import '../Styles/responsive.scss';

body{
    width: 100%;
    overflow-x: hidden;
    // background: #F2F2F2;
    user-select: none;
}