@import "../../Styles/Variable.scss";
.about-section-wrapper{
    .thumnail{
        width: 100%;
    }
    .floating--content{
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
    }

    .video-container{
        position: relative;
        .overlay-content{
            position: absolute;
            width: 100%;
            height: 100%;
            background: #0000005b;
            top: 0px;
            left: 0px;
        }
        img{
          width: 100%;
        }
    }
}

