@import "../../Styles/Variable.scss";
.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.alert{
    transition: 1s ease-in-out all !important;
}


.sub-navigation-wrapper{
    color: black;
    h4{
        cursor: pointer;
    }
    .child-sub-menu{
        padding-top: 20px;
        p{
            color: $linkColor;
            cursor: pointer;
        }
        
        margin-bottom: 40px;
    }
    padding-bottom: 40px;
}