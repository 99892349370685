@import "../../Styles/Variable.scss";
.top-bar-wrapper{
    background: $primaryColor;
    height: 37px;
    color: white;
    font-size: 14px;
    .notices{
        position: relative;
        width: 100%;

        p{
            position: absolute;
            z-index: 1000;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }
    }
}

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }