@import "../../Styles/Variable.scss";
$radius: 2;
.svg {
    position: absolute;
    width: 0;
    height: 0;
  }
.course-card-wrapper{
    -webkit-clip-path: url(#my-clip-path);
    clip-path: url(#my-clip-path);
    cursor: pointer;
    &.left{
        -webkit-clip-path: url(#my-clip-path-left);
    clip-path: url(#my-clip-path-left);
    }
    img{
        min-width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: auto;
        min-height: 100%;
    }
    .course-details{
        padding: 10px 30px;
        position: absolute;
        bottom: 48px;
        z-index: 1;
        color: white;
        height: 100%;
        width: 100%;
        top: 0px;
        background: linear-gradient(0deg, rgba(1, 112, 62, 0.74) 28.86%, rgba(1, 112, 62, 0) 96.67%);
        h2{
            font-weight: bolder;
        }
        .button-custom{
            span{
                margin-right: 10px;
            }
        }
        
    }
    .course-image{
        height: 551px;
        overflow: hidden;
    }
}