@import "../../Styles/Variable.scss";
.pagination-wrapper{
    display: flex;
    .pagination-dots{
        margin: 5px;;
        width: 20px;
        height: 20px;
        background: #cccccc;
        border: 2px solid white;
        border-radius: 50%;
        cursor: pointer;
        &.active{
            background: $secondaryColor;
        }
    }
}