.voice-wrapper{
    img{
        width: 100%;
    }
    .person-image{
        max-width: 200px;
        border-radius: 10px;
        overflow: hidden;
    }
    .voice-content{
        background: #eeeeee;
        border-radius: 10px;
        width: 50%;
    }
}