@media (min-width: 1400px){
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1340px;
    }
}
@media (min-width: 1600px){
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1540px;
    }
}
p{
    font-family: "Poppins", sans-serif;
}

.form-control{
    border-radius: 0px;
    padding: 10px 30px;
    height: 50px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.12);
    border: none;
    font-weight: bold;
    &:focus{
        outline: none;
        border: none;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.12);

    }
    &[type=radio]{
        width: 40px;
        display: inline-flex;
        margin-right: 10px;
        border: none;
    }

}
.form-group{
    label{
        color: gray;
    }
}
select{
    &.form-control{
        font-weight: bold;

    }
}
.text-bold{
    font-weight: 600;
}
.text-bolder{
    font-weight: bold;
}
.text-white{
    color: white;
}
.form-group{
    .form-error{
        color: red;
        padding: 10px 0px;
    }
}
.gender-select{
    label{
        width: 80px;
        height: 80px;
        border-radius: 10px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.12);
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.7s ease-in-out all;
        &.active{
            background: rgba(0, 123, 255, 0.59);
        }
    }
}

.paragraph{
    font-size: 18px;
    line-height: 34px;
}

.background--primary{
    background-color: $primaryColor;
    color: white;
}

.background--gray{
    background-color: #F0F0F0;
    color: black;
}

.text-paragraph{
    &.light{
        color: #4E4E4E;
        font-size: 16px;
    }
}


  /* triangle */
  .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-50%);
    transform: translateX(-40%) translateY(-50%);
    transform-origin: center center;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 25px solid #fff;
    z-index: 100;
    -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  /* pulse wave */
  .play-btn:before {
    content: "";
    position: absolute;
    width: 150%;
    height: 150%;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate1 2s;
    animation: pulsate1 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(255, 255, 255, .75);
    top: -25%;
    left: -25%;
    background: rgba(198, 16, 0, 0);
  }
  
  @-webkit-keyframes pulsate1 {
    0% {
      -webkit-transform: scale(0.6);
      transform: scale(0.6);
      opacity: 1;
      box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
      box-shadow: none;
  
    }
  }
  
  @keyframes pulsate1 {
    0% {
      -webkit-transform: scale(0.6);
      transform: scale(0.6);
      opacity: 1;
      box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
      -webkit-transform: scale(1, 1);
      transform: scale(1);
      opacity: 0;
      box-shadow: none;
  
    }
  }

  
.play-btn {
    width: 100px;
    height: 100px;
    background: radial-gradient( rgba($primaryColor, 0.6) 60%, rgba(255, 255, 255, 1) 62%);
    border-radius: 50%;
    position: relative;
    display: block;
    margin: 100px auto;
    box-shadow: 0px 0px 25px 3px rgba($primaryColor, 0.6);
  }
  
