@import "../../Styles/Variable.scss";

.event-wrapper {
    padding: 30px 0px;
    padding-top: 20px;
    cursor: pointer;

    .title {
        height: 60px;
        font-weight: 400;
        text-transform: uppercase;
    }
    .event-tag{
        background: $secondaryColor;
        padding: 5px 30px;
        display: inline-block;
        text-transform: uppercase;
        color: white;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 10px;
    }


        .event--details {

            h4 {
                font-weight: 600;
                font-size: 20px;

            }
            .text-gray{
                color: gray;
                font-size: 14px;

            }
            .paragraph{
                font-size: 16px;
                color: black;
                line-height: 180%;
                font-weight: 500;
            }
        }



    

}