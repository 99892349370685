.slide-navigation-wrapper{
    width: 100%;
    .navigation{
        display: flex;
        flex-direction: column-reverse;
        color: white;
        cursor: pointer;
        &.disabled{
            color: gray;
        }
        span{
            display: block;
            text-transform: uppercase;
            font-weight: 600;
        }
        &.left{
            span{
                text-align: right;
            }
        }
        &.right{
            span{
                text-align: left;
            }
        }
    }
}