.course-deattails-wrapper {
    .cover-image {
        width: 100%;
    }

    .accordion {
        .card {
            border: 1px solid #626A77;
            border-radius: 10px;

            p {
                color: #4E4E4E;
                font-size: 18px;
                line-height: 200%;
            }

            .card-header {
                background: white;
                border-bottom: 1px solid #eeeeee;
                padding-top: 20px;
                padding-bottom: 20px;
                cursor: pointer;
            }
        }

        .card-body {
            text-align: justify;
            white-space: normal;


            /* Forces normal spacing */
            // text-justify: inter-word;
            // line-break: anywhere;
            &::after {
                content: normal;
            }
        }

        table {
            width: 100%;
        }
    }
}