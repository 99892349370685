.img-responsive{
    height: 200px;
    overflow: hidden;
    display: block;
    img{
        width: 100%;
        height: auto;
    }
}
.album-gallery{
    padding-left: 0px !important;
    .ReactGridGallery{
        display: block !important;

    }
    .modal-dialog{
        width: 100vw;
        max-width: 100vw;
        height: 100vh;
        margin-top: 0px;
        margin-left: 0px;
        margin-bottom: 0px;
    }
    .modal-content{
        height: 100%;
        padding: 0px 20px;
    }
}