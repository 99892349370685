@import '../../../Styles/Variable.scss';
.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px;
  /* gutter size offset */
  width: 100%;
}

.my-masonry-grid_column {
  padding-left: 15px;
  /* gutter size */
  background-clip: padding-box;
  height: 250px;
  overflow: hidden;
}

/* Style your items */
.my-masonry-grid_column>div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 15px;
}

.ReactGridGallery {
  display: flex;
}

.indiana-scroll-container {

  /* width */
&::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
&::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
&::-webkit-scrollbar-thumb {
  background: $primaryColor;
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: $primaryColor;
}
}