@import "../../Styles/Variable.scss";

.custom-navigation {
    z-index: 5;
    width: 100%;
    top: 0;
    left: 0;
    transition: 0.4s ease-in-out all;

    .nav-link {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bolder;
        text-transform: uppercase;
        color: white;
    }

    &.solid-nav {
        background: white !important;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.09);

        .nav-link {

            color: $primaryColor;
        }
    }

    .sub--menu {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        z-index: 1;
        display: none;
        min-height: 200px;

    }

    .overlay {
        background: white;
        width: 100%;
        min-height: 100%;
        position: absolute;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        transition: 0.5s ease all;
        top: 0px;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.09);

        &.visible {
            visibility: visible;
            opacity: 1;
        }

    }

    &.light-nav {
        // background: white !important;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.09);

        .nav-link {

            color: $primaryColor;
        }

        .border-bottom-custom {
            border-bottom: 1px solid black;

        }
    }

    .button-style {
        border: 1px solid white;
        transition: 0.3s ease-in-out all;

        &:hover {
            background: white;
            color: $primaryColor;
        }
    }

    &.solid-nav {
        .button-style {
            border: 1px solid $primaryColor;

            &:hover {
                background: $primaryColor;
                color: white;
            }
        }
    }
}

.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.example-leave {
    opacity: 1;
}

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.parent-menu {
    ul {
        list-style-type: none;
        margin-left: 0px;
        padding-left: 0px;
    }

    .child-menu-wrapper {
        display: none;
        padding-top: 30px;
        position: absolute;
        transition: 0.5s ease all;
        z-index: 1;

        .child-menu {
            background: white;
            color: $primaryColor;
            padding: 20px 15px;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            .child-section{
                margin-bottom: 20px;
                span{
                    // font-weight: normal;
                    color: gray;
                }
            }

        }
    }

    &:hover {
        .child-menu-wrapper {
            display: block;
        }
    }
}