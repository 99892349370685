.about-page{
    .text-paragraph{
        line-height: 200%;
    }
    .video-container{
        position: relative;
        .overlay-content{
            position: absolute;
            width: 100%;
            height: 100%;
            background: #0000005b;
            top: 0px;
            left: 0px;
        }
        img{
            width: 100%;
        }
        border-radius: 10px;
        overflow: hidden;
    }
    .mission-vision{
        p{
            line-height: 200%;
            // text-align: justify;
        }
    }

}

.videoModal{
    display: flex !important;
    justify-content:center;
    .modal-dialog{
        max-width: unset;

    }
}